<template>
  <div class="hk-content">
    <div v-if="status == 3">
      <h4><i class="iconfont icon-time"></i></h4>
    </div>
    <div v-if="status != 3">
      <div v-if="status == 0">
        <h4><i class="iconfont icon-fail"></i> {{ $t('common.paymentStatus.unpaid', [invoice]) }}</h4>
      </div>
      <div v-if="status == 1">
        <h4><i class="iconfont icon-success"></i> {{ $t('common.paymentStatus.success', [invoice]) }}</h4>
      </div>
      <div v-if="status == -1">
        <h4><i class="iconfont icon-fail"></i> {{ $t('common.paymentStatus.failed', [invoice]) }}</h4>
      </div>
      <div v-if="status == 2">
        <h4><i class="iconfont icon-success"></i> {{ $t('common.paymentStatus.offline', [invoice]) }}</h4>
      </div>
      <a @click="goHome">{{ $t('common.back') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HkPayment',
  data () {
    let state = 3;
    if ( this.$router.history.current.query.error_msg ) {
      state = -1
    } else {
      this.getPaymentStatus(3);
    }
    return {
      paymentStatus: state,
    }
  },
  computed: {
    invoice: function() {
      return this.$router.history.current.query.sn
    },
    status: function() {
      return this.paymentStatus
    }
  },
  methods: {
    goHome () {
      this.$router.push({
        name: 'home'
      })
    },
    timeout () {
      setTimeout(function() {
        if ( paying )
        this.timeout()
        else {
          return
        }
      }, 1500)
    },
    async getPaymentStatus (n) {
      let sn = this.$router.history.current.query.sn;
      let self = this;
      if (self.paymentStatus == undefined) {
        self.paymentStatus = 3
      }
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/pay/status';
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      };
      let formData = new FormData();
      formData = {
        order_sn: sn,
      }
      if ( self.paymentStatus != 1 && n >= 0 ) {
        let res = await this.axios.post(apiCall, formData, header)
        .then(function(response) {
          if (response.data.data.is_paid == 1 || n == 0) {
            self.paymentStatus = response.data.data.is_paid;
          }
          n--
          setTimeout(function() {
            self.getPaymentStatus(n)
          }, 1500)
        })
        .catch(function(error) {
          console.log(error)
        })
      } else {
        return
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hk-content {
  color: $blockColor;
  text-align: center;
  padding: 9% 0;
  background: #303030 ;

  h4 {
    font-size: 42px;
  }

  a {
    display: block;
    font-size: $titleSize;
    color: $themeColor;
    cursor: pointer;
    margin-top: 40px;
  }
}
</style>
